.contact-section {
  background-color: #f5f5f5;
  padding: 4rem 2rem;
  text-align: center;
}

.contact-section h2 {
  color: #33373a;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info, .contact-form {
  flex-basis: 48%;
}

.contact-info h3 {
  color: #33373a;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.contact-info p {
  color: #666;
  margin-bottom: 1.5rem;
  text-align: left;
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.contact-info li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.contact-info i {
  color: #6cc3e3;
  margin-right: 0.5rem;
  width: 20px;
}

.contact-info a {
  color: #33373a;
  text-decoration: none;
}

.social-links {
  margin-top: 2rem;
  text-align: left;
}

.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #6cc3e3;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-right: 0.5rem;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: #207898;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form textarea {
  height: 150px;
  resize: vertical;
}

.contact-form button {
  background-color: #6cc3e3;
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #207898;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-info, .contact-form {
    flex-basis: 100%;
  }

  .contact-info {
    margin-bottom: 2rem;
  }
}
