.blog-section {
  --brandmark-image: none;
  background-color: #ffffff;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}

.blog-section::before {
  content: '';
  position: absolute;
  top: -25%;
  left: -25%;
  width: 100%;
  height: 150%;
  background-image: var(--brandmark-image);
  background-size: 80% auto;
  background-repeat: no-repeat;
  background-position: left center;
  opacity: 0.15;
  z-index: 0;
}

.blog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.blog-image {
  flex: 1;
  max-width: 500px;
}

.image-placeholder {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  background-color: #f0f0f0;
  border-radius: 10px;
}

.blog-content {
  flex: 1;
  max-width: 500px;
}

.blog-content h2 {
  color: #33373a;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.blog-content h3 {
  color: #33373a;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.blog-content p {
  color: #666;
  margin-bottom: 1.5rem;
}

.read-more {
  display: inline-block;
  padding: 0.75rem 2rem;
  background-color: var(--accent-color3);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.read-more:hover {
  background-color: var(--accent-color2); /* You can adjust this to a darker shade of accent-color3 if preferred */
}

@media (max-width: 768px) {
  .blog-container {
    flex-direction: column;
  }

  .blog-image, .blog-content {
    max-width: 100%;
  }
}
