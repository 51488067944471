.about-section {
  background-color: #f5f5f5;
  padding: 4rem 2rem;
  text-align: center;
}

.about-section h2 {
  color: #33373a;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.about-text {
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.6;
  color: #666;
}

.service-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;
}

.service-card .icon {
  font-size: 3rem;
  color: #6cc3e3;
  margin-bottom: 1rem;
  display: block;
}

.service-card h3 {
  color: #33373a;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-card p {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .service-cards {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 100%;
    max-width: 300px;
    margin-bottom: 2rem;
  }
}

.stats-container {
  display: flex;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
}

.stat-item h3 {
  font-size: 1.5rem;
  color: #6cc3e3;
  margin-bottom: 0.5rem;
}

.stat-item p {
  color: #666;
}
