.services {
  text-align: center;
  padding: 4rem 2rem;
  background-color: var(--accent-color3);
}

.services h2 {
  color: var(--text-color);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.services > p {
  color: var(--text-color);
  max-width: 800px;
  margin: 0 auto 3rem;
}

.service-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.service-card {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: left;
}

.service-card .icon {
  font-size: 2.5rem;
  color: var(--accent-color1);
  margin-bottom: 1rem;
  display: block;
}

.service-card h3 {
  color: var(--main--bg-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-card p {
  color: var(--main--bg-color);
  margin-bottom: 1rem;
}

.service-card ul {
  color: var(--main--bg-color);
  padding-left: 1.5rem;
}

.service-card li {
  margin-bottom: 0.5rem;
}

@media (max-width: 900px) {
  .service-card {
    width: 100%;
    max-width: 400px;
  }
}
