.plans-section {
  background-color: #f5f5f5;
  padding: 4rem 2rem;
  text-align: center;
}

.plans-section h2 {
  color: #33373a;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.plans-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}

.plan {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.plan:hover {
  transform: translateY(-5px);
}

.plan.featured {
  background-color: #6cc3e3;
  color: white;
}

.plan h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.price span {
  font-size: 1rem;
  font-weight: normal;
}

.plan ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
}

.plan li {
  margin-bottom: 0.5rem;
}

.plan-button {
  display: inline-block;
  padding: 0.75rem 2rem;
  background-color: #6cc3e3;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.plan-button:hover {
  background-color: #207898;
}

.featured .plan-button {
  background-color: white;
  color: #6cc3e3;
}

.featured .plan-button:hover {
  background-color: #f0f0f0;
}

@media (max-width: 900px) {
  .plan {
    width: 100%;
    max-width: 400px;
  }
}
