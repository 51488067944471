:root {
  --text-color: white;
  --main--bg-color: #33373a;
  --accent-color1: #6cc3e3;
  --accent-color2: #29a9b8;
  --accent-color3: #207898;
  --accent--color4: #f7f4a7;
  --accent-color5: #dc5d5d;
 

}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  color: var(--main--bg-color);
  padding-top: 80px; /* Adjust this value based on your header's height */
}

.App {
  text-align: center;
}

.container {
  padding: 0 2rem; /* Consistent padding for both header and main content */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff; /* or any color that matches your design */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* optional: adds a subtle shadow */
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-color);
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 1.5rem;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
}

.main-content {
  background-color: #ffffff; /* or any color you prefer */
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.text-content {
  max-width: 50%;
  text-align: left;
  margin-left: 2rem; /* Ensure this matches the padding in .header */
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.text-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: white;
}

.contact-button {
  background-color: var(--accent-color3);
  color: var(--text-color);
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: var(--accent-color1);
  color: var(--text-color);
}

.image-content {
  max-width: 45%;
}

.placeholder-image {
  width: 100%;
  height: 300px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-image::after {
  content: 'Image Placeholder';
  color: #999;
}

.logo-image {
  width: 100px; /* Adjust this value as needed */
  height: auto; /* This maintains the aspect ratio */
}

.header-logo {
  width: 200px; /* Adjust this value as needed */
  height: auto;
}

.main-content-logo {
  width: 150px; /* Larger size for main content */
  height: auto;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .text-content, .image-content {
    max-width: 100%;
  }
  
  .header {
    padding: 0.25rem 1rem; /* Even smaller padding for mobile */
  }
  
  .header-logo {
    width: 150px; /* Slightly smaller logo for mobile */
  }
}

/* Ensure other header elements don't grow too large */
.header nav {
  display: flex;
  align-items: center;
}

.header nav ul {
  margin: 0;
  padding: 0;
}

.header nav ul li {
  margin-left: 1rem;
}

.content {
  padding-top: 80px; /* Adjust this value based on your header height */
}
